<template>
  <div class="my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="auth-wrapper">
          <div class="card overflow-hidden">
            <div class="card-title card-header">
              <img src="@/assets/images/logo.svg" width="200px" />
            </div>
            <div class="card-body">
              <b-alert v-model="isAuthError" variant="danger" dismissible>
                {{ authError }}
              </b-alert>

              <b-form @submit.prevent="tryToLogIn">
                <b-form-group
                  id="email-input-group"
                  label="E-mail"
                  label-for="email-input"
                >
                  <b-form-input
                    id="email-input"
                    v-model="email"
                    type="text"
                    placeholder="Digite o e-mail"
                    :class="{ 'is-invalid': submitted && $v.email.$error }"
                  />

                  <div
                    v-if="submitted && $v.email.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.email.required">
                      {{ $t('login.field_obrigatory') }}
                    </span>
                    <span v-if="!$v.email.email">
                      {{ $t('login.invalid_email') }}
                    </span>
                  </div>
                </b-form-group>

                <b-form-group
                  id="password-input-group"
                  label="Senha"
                  label-for="password-input"
                >
                  <b-form-input
                    id="password-input"
                    v-model="password"
                    type="password"
                    placeholder="Digite a senha"
                    :class="{ 'is-invalid': submitted && $v.password.$error }"
                  />

                  <div
                    v-if="submitted && !$v.password.required"
                    class="invalid-feedback"
                  >
                    {{ $t('login.field_obrigatory') }}
                  </div>
                </b-form-group>

                <div class="mt-3 text-center">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="btn-block"
                    v-if="!loading"
                  >
                    {{ $t('login.entry') }}
                  </b-button>

                  <b-spinner
                    variant="primary"
                    label="Carregando"
                    class="mt-1"
                    v-if="loading"
                  />
                </div>

                <div class="mt-4 text-center">
                  <router-link to="/resetPassword" class="text-muted">
                    <i class="fas fa-lock mr-1"></i>
                    {{ $t('login.forgot_password') }}
                  </router-link>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import router from '@/router';

const { mapActions } = createNamespacedHelpers('auth');
let vm;
export default {
  data() {
    return {
      email: '',
      password: '',
      submitted: false,
      authError: null,
      loading: false,
      isAuthError: false
    };
  },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required }
  },
  mounted() {
    vm = this;
  },
  methods: {
    ...mapActions(['LOGIN']),
    tryToLogIn() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;

      this.LOGIN({
        login: this.email,
        password: this.password
      })
        .then((_response) => {
          if (_response.isAuthenticated) {
            router.replace({
              name: 'home'
            });
          } else {
            this.isAuthError = true;
            this.authError = vm.$t('login.invalid');
          }
        })
        .catch(() => {
          this.authError = vm.$t('login.error');
          this.isAuthError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/design/index.scss';

.auth-wrapper {
  width: 320px;
}

.card-header {
  background: $sidebar-dark-bg;
  text-align: center;
  padding: 20px;
}
</style>
